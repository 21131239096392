var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-overflow": "",
        "open-on-hover": "",
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      icon: "",
                      color: "primary",
                      disabled: _vm.disabled,
                    },
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("volume_up")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "dn-volume" },
        [
          _c("v-slider", {
            attrs: { min: -52, max: 10, value: _vm.volume, vertical: "" },
            on: {
              input: function (e) {
                return _vm.$emit("vol", e)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }