var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-overflow": "", disabled: _vm.disabled },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      icon: "",
                      color: "primary",
                      disabled: _vm.disabled,
                    },
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("tune")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { staticClass: "dn-pack-settings" },
        [
          _c("v-card-text", [
            _c("div", { staticClass: "dn-pack-settings__section" }, [
              _c(
                "div",
                { staticClass: "dn-pack-settings__section-item" },
                [
                  _c("p", [_vm._v("Kit")]),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass:
                                      "dn-pack-settings__section-dropdown",
                                    attrs: { dark: "", text: "" },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentStyle.name) + " "
                                  ),
                                  _c("v-icon", [_vm._v("keyboard_arrow_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.styles, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function () {
                                  return _vm.handleStyleChange(item)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "dn-pack-settings__section-item" },
                [
                  _c("p", [_vm._v("Tempo")]),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass:
                                      "dn-pack-settings__section-dropdown",
                                    attrs: { dark: "", text: "" },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentTempo.name) + " "
                                  ),
                                  _c("v-icon", [_vm._v("keyboard_arrow_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.tempos, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function () {
                                  return _vm.handleTempoChange(item)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }