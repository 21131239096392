var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation-drawer",
      attrs: {
        permanent: _vm.$vuetify.breakpoint.mdAndUp,
        app: "",
        "hide-overlay": "",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "div",
        { staticClass: "navigation-drawer__logo-container" },
        [_c("logo")],
        1
      ),
      _c("div", { staticClass: "navigation-drawer__nav" }, [
        _c(
          "ul",
          { staticClass: "navigation-drawer__nav-list" },
          _vm._l(_vm.menuItems, function (item) {
            return _c(
              "li",
              {
                key: item.name,
                class:
                  "navigation-drawer__nav-list-item" +
                  (item === _vm.activeItem
                    ? " navigation-drawer__nav-list-item--active"
                    : ""),
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ path: item.path })
                  },
                },
              },
              [
                _c("i", { class: "dn-icon dn-icon--" + item.icon }),
                _vm._v(" " + _vm._s(item.name) + " "),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "v-container",
        { staticClass: "navigation-drawer__user-actions" },
        [
          !_vm.user.authenticated
            ? [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      block: "",
                      large: "",
                      color: "accent",
                      to: _vm.routes.LOGIN,
                    },
                  },
                  [_vm._v("Login")]
                ),
              ]
            : [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-5",
                        attrs: { to: "settings", icon: "", ripple: false },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            staticClass: "avatar",
                            attrs: { color: "primary", size: 70 },
                          },
                          [
                            _c("v-icon", { staticClass: "avatar__icon" }, [
                              _vm._v("build"),
                            ]),
                            _vm.user.profile.avatarUrl
                              ? _c("v-img", {
                                  attrs: { src: _vm.user.profile.avatarUrl },
                                })
                              : _c("span", [_vm._v("DN")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-3",
                    attrs: { block: "", text: "", large: "", color: "white" },
                    on: { click: _vm.logout },
                  },
                  [_vm._v("Logout")]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }