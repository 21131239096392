var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-player__controls" },
    [
      _vm.condensed
        ? [
            _c(
              "v-btn",
              _vm._b(
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle-play")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [
                !_vm.playing
                  ? _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("play_arrow"),
                    ])
                  : _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("pause"),
                    ]),
              ],
              1
            ),
            _c(
              "v-btn",
              _vm._b(
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [_c("v-icon", [_vm._v("fast_forward")])],
              1
            ),
          ]
        : [
            _vm.showVolume
              ? _c("volume", {
                  on: {
                    vol: function (e) {
                      return _vm.$emit("volume", e)
                    },
                  },
                })
              : _vm._e(),
            _c(
              "v-btn",
              _vm._b(
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [_c("v-icon", [_vm._v("fast_rewind")])],
              1
            ),
            _c(
              "v-btn",
              _vm._b(
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle-play")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [
                !_vm.playing
                  ? _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("play_arrow"),
                    ])
                  : _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("pause"),
                    ]),
              ],
              1
            ),
            _c(
              "v-btn",
              _vm._b(
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [_c("v-icon", [_vm._v("fast_forward")])],
              1
            ),
            _c(
              "v-btn",
              _vm._b(
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("loop")
                    },
                  },
                },
                "v-btn",
                _vm.btnAttrs,
                false
              ),
              [
                _c(
                  "v-icon",
                  { attrs: { color: _vm.looping ? "success" : "primary" } },
                  [_vm._v("loop")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }