import { render, staticRenderFns } from "./Player.vue?vue&type=template&id=038003d0&scoped=true&"
import script from "./Player.vue?vue&type=script&lang=ts&"
export * from "./Player.vue?vue&type=script&lang=ts&"
import style0 from "./Player.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./Player.vue?vue&type=style&index=1&id=038003d0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038003d0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSlider,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('038003d0')) {
      api.createRecord('038003d0', component.options)
    } else {
      api.reload('038003d0', component.options)
    }
    module.hot.accept("./Player.vue?vue&type=template&id=038003d0&scoped=true&", function () {
      api.rerender('038003d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/player-v2/Player.vue"
export default component.exports