var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", _vm._b({}, "div", _vm.playerAttrs, false), [
    _c(
      "div",
      { staticClass: "dn-player__container" },
      [
        _vm.expanded
          ? [
              _c("img", {
                staticClass: "dn-player__bg",
                attrs: { src: _vm.thumbnail },
              }),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", outlined: "", small: "" },
                  on: { click: _vm._handleCloseButtonClick },
                },
                [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                1
              ),
              _c("img", {
                staticClass: "dn-player__thumbnail",
                attrs: { src: _vm.thumbnail },
              }),
              _c("v-spacer"),
              _c("div", { staticClass: "dn-player__expanded-meta" }, [
                _c("div", { staticClass: "dn-player__subtitle" }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ]),
                _c("div", { staticClass: "dn-player__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "dn-player__track-position" },
                [
                  _c(
                    "v-slider",
                    {
                      attrs: {
                        min: 0,
                        max: _vm.engine.duration,
                        value: _vm.engine.position,
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "overline",
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                        },
                        [_vm._v(_vm._s(_vm.position))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "overline",
                          attrs: { slot: "append" },
                          slot: "append",
                        },
                        [_vm._v(_vm._s(_vm.duration()))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("controls", {
                attrs: {
                  condensed: false,
                  playing: _vm.playing,
                  looping: _vm.looping,
                  showVolume: true,
                },
                on: {
                  "toggle-play": _vm._handleTogglePlay,
                  next: _vm._handleNext,
                  back: _vm._handleBack,
                  loop: _vm._handleLoop,
                  volume: _vm._handleVolume,
                },
              }),
              _c(
                "div",
                { staticClass: "dn-player__options" },
                [_c("settings", { on: { update: _vm._handleUpdate } })],
                1
              ),
            ]
          : [
              _c(
                "div",
                {
                  staticClass: "dn-player__thumbnail-container",
                  on: { click: _vm._handleThumbnailClick },
                },
                [
                  _c("img", {
                    staticClass: "dn-player__thumbnail",
                    attrs: { src: _vm.thumbnail },
                  }),
                ]
              ),
              _c("div", { staticClass: "dn-player__meta-container" }, [
                _c("div", { staticClass: "dn-player__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("div", { staticClass: "dn-player__subtitle" }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ]),
              ]),
              _vm.$vuetify.breakpoint.smAndDown ? _c("v-spacer") : _vm._e(),
              _c("controls", {
                attrs: {
                  condensed: _vm.$vuetify.breakpoint.smAndDown,
                  playing: _vm.playing,
                  looping: _vm.looping,
                },
                on: {
                  "toggle-play": _vm._handleTogglePlay,
                  next: _vm._handleNext,
                  back: _vm._handleBack,
                  loop: _vm._handleLoop,
                  volume: _vm._handleVolume,
                },
              }),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    { staticClass: "dn-player__track-position mr-10" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade-transition" } },
                        [
                          _vm.engine.duration
                            ? _c(
                                "v-slider",
                                {
                                  attrs: {
                                    min: 0,
                                    max: _vm.engine.duration,
                                    value: _vm.engine.position,
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "overline",
                                      attrs: { slot: "prepend" },
                                      slot: "prepend",
                                    },
                                    [_vm._v(_vm._s(_vm.position))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "overline",
                                      attrs: { slot: "append" },
                                      slot: "append",
                                    },
                                    [_vm._v(_vm._s(_vm.duration()))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : !_vm.$vuetify.breakpoint.mdAndDown &&
                  !_vm.$vuetify.breakpoint.lgAndUp
                ? _c("v-spacer")
                : _vm._e(),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    [
                      _c("volume", { on: { vol: _vm._handleVolume } }),
                      _c("settings", { on: { update: _vm._handleUpdate } }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }