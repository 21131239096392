var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "app" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transition" } },
        [
          _vm.$vuetify.breakpoint.mdAndDown &&
          _vm.showToolbar &&
          _vm.showMenuIcon
            ? _c(
                "v-app-bar",
                {
                  staticClass: "elevation-0 pa-4 pt-2",
                  attrs: { color: "transparent", app: "" },
                },
                [
                  _c("v-app-bar-nav-icon", {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toggleSidebar.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showSidebar ? _c("sidebar", { ref: "sidebar" }) : _vm._e(),
      _c(
        "v-main",
        {
          staticClass: "app__content pt-0 darkGrey",
          attrs: { fluid: "", clipped: "" },
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade-transition", mode: "out-in" } },
            [
              _c("router-view", {
                key: "content",
                on: {
                  "loop:click": _vm.handleLoopClick,
                  "load-player": _vm.handleLoadPlayer,
                  "destroy-player": _vm.handleDestroyPlayer,
                },
              }),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide-y-reverse-transition", mode: "out-in" } },
            [
              _vm.showPlayer
                ? _c(
                    "div",
                    { staticClass: "dn-player-container" },
                    [_c("player", { ref: "player" })],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("snackbar"),
      _c("audio", { attrs: { id: "audio_container" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }